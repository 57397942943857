import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute";
import { useSignInStore } from "store/useSignInStore";
import { CodeView } from "views/code";
import { SignIn } from "views/auth/signIn";
import { AdminLayout } from "layouts/admin";
import { TasksView } from "views/admin/default";
import { CreateTaskView } from "views/admin/default/CreateTaskView";
import { TaskDetailsView } from "views/admin/default/TaskDetails";
import { createStandaloneToast } from "@chakra-ui/react";
import { RequestsView } from "views/admin/default/Requests";
import { RequestDetailsView } from "views/admin/default/Requests/RequestDetails";
import { DashboardView } from "views/admin/default/dashboard";
import { UsersView } from "views/admin/default/users";
import { UserDetailsView } from "views/admin/default/users/UserDetails";
import { ReferralsCount } from "views/referralsCount";
import { LeaderboardView } from "views/admin/default/leaderboard";
// import { ProjectsView } from "views/admin/default/projects";
// import { ProjectDetailsView } from "views/admin/default/projects/ProjectDetails";
// import { CreateProject } from "views/admin/default/projects/CreateProject";
// import { RoundDetails } from "views/admin/default/projects/components/round/RoundDetails";
// import { NetworksView } from "views/admin/default/networks";
// import { TokensView } from "views/admin/default/tokens";
// import { CreateNetwork } from "views/admin/default/networks/CreateNetwork";
// import { NetworkDetails } from "views/admin/default/networks/NetworkDetails";
// import { CreateToken } from "views/admin/default/tokens/CreateToken";
// import { TokenDetails } from "views/admin/default/tokens/TokenDetails";
// import { TasksStatisticView } from "views/admin/default/tasksStatistic";

export const toast = createStandaloneToast();

export const App = () => {
  const { emailSignIn } = useSignInStore();
  return (
    <Routes>
      <Route path="/auth" element={<SignIn />} />
      <Route
        path="/code"
        element={
          <PrivateRoute redirectCondition={!emailSignIn}>
            <CodeView />
          </PrivateRoute>
        }
      />
      <Route
        path="/requests"
        element={
          <PrivateRoute>
            <AdminLayout>
              <RequestsView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/requests/:requestID"
        element={
          <PrivateRoute>
            <AdminLayout>
              <RequestDetailsView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <AdminLayout>
              <DashboardView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <AdminLayout>
              <UsersView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/leaderbaord"
        element={
          <PrivateRoute>
            <AdminLayout>
              <LeaderboardView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/referral-count"
        element={
          <PrivateRoute>
            <AdminLayout>
              <ReferralsCount />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/users/:userID"
        element={
          <PrivateRoute>
            <AdminLayout>
              <UserDetailsView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/projects"
        element={
          <PrivateRoute>
            <AdminLayout>
              <ProjectsView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/create"
        element={
          <PrivateRoute>
            <AdminLayout>
              <CreateProject />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:projectID"
        element={
          <PrivateRoute>
            <AdminLayout>
              <ProjectDetailsView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:projectID/rounds/:roundID"
        element={
          <PrivateRoute>
            <AdminLayout>
              <RoundDetails />
            </AdminLayout>
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/tasks"
        element={
          <PrivateRoute>
            <AdminLayout>
              <TasksView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/tasksStatistic"
        element={
          <PrivateRoute>
            <AdminLayout>
              <TasksStatisticView />
            </AdminLayout>
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/tasks/:taskID"
        element={
          <PrivateRoute>
            <AdminLayout>
              <TaskDetailsView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks/create"
        element={
          <PrivateRoute>
            <AdminLayout>
              <CreateTaskView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/networks"
        element={
          <PrivateRoute>
            <AdminLayout>
              <NetworksView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/networks/create"
        element={
          <PrivateRoute>
            <AdminLayout>
              <CreateNetwork />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/networks/:networkID"
        element={
          <PrivateRoute>
            <AdminLayout>
              <NetworkDetails />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/tokens"
        element={
          <PrivateRoute>
            <AdminLayout>
              <TokensView />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/tokens/create"
        element={
          <PrivateRoute>
            <AdminLayout>
              <CreateToken />
            </AdminLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/tokens/:tokenID"
        element={
          <PrivateRoute>
            <AdminLayout>
              <TokenDetails />
            </AdminLayout>
          </PrivateRoute>
        }
      /> */}
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};
