import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { PaginatedTable } from "./PaginatedTable";
import CheckTable from "./CheckTable";
import { useTasksStore } from "store/useTasksStore";
import { tasksStore } from "store/useTasksStore";
import { tasksService } from "services/tasksService";

const textColor = "light.400";

export const TableWithHeader = ({
  columns,
  tableData,
  tableName,
  onTopBarButtonClick,
  topBarButtonText,
  isDataLoading,
  tableStore,
  tableService,
}) => {
  const { isSetOrderPressed } = useTasksStore();

  const handleSetOrderButton = () => {
    if (isSetOrderPressed) {
      tasksService.handleSubmitOrder();
      tasksStore.setState({ isSetOrderPressed: false });
    } else {
      tasksStore.setState({ isSetOrderPressed: true });
    }
  }
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Box px={4} pt={{ base: "20px", md: "20px", xl: "20px" }}>
        <Flex px="25px" justify="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {tableName}
          </Text>
          {!!topBarButtonText && (
            <Flex gap={5}>
              <Button onClick={handleSetOrderButton} color="dark.400">
                {isSetOrderPressed ? "Submit": "Set Order"}
              </Button>
              {isSetOrderPressed &&  
                <Button onClick={() => tasksStore.setState({ isSetOrderPressed: false })} backgroundColor="red">
                  Close
                </Button>}
             
              <Button onClick={onTopBarButtonClick} color="dark.400">
                {topBarButtonText}
              </Button>
            </Flex>
          )}
        </Flex>
        {!!tableStore && !!tableService ? (
          <PaginatedTable
            columnsData={columns}
            tableData={tableData}
            isDataLoading={isDataLoading}
            tableStore={tableStore}
            tableService={tableService}
          />
        ) : (
          <CheckTable columnsData={columns} tableData={tableData} />
        )}
      </Box>
    </Card>
  );
};
