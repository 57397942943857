import { create, useStore } from "zustand";

// export interface Leader {
//   position: number;
//   telegramUserId: string;
//   referralsCount?: number;
//   profileIcon: string;
//   avatarId: string;
//   username: string;
// }

// export interface LeaderWithImg {
//   position: number;
//   telegramUserId: string;
//   referralsCount: number;
//   profileIcon: string;
//   avatarId: string;
//   imgUrl: string;
// }

// export interface Pool {
//   amount: number;
//   giveawayDate: Date;
// }

// export type LeaderBoardStore = {
//   leadersList: Array<Leader>;
//   userResults: Leader | null;
//   isLoading: boolean;
//   pool: Pool | null;
//   isRulesModalOpened: boolean;
// };

// type ResponseUser = {
//   position: number;
//   telegram_user_id: string;
//   profile_icon: string;
//   avatar_id: string;
//   username: string;
// }

// export type LeaderboardResponse = {
//   participants: Array<ResponseUser>;
//   currentUser: ResponseUser;
// }

export const leaderBoardStore = create(() => ({
  leadersList: [],
  userResults: null,
  isLoading: true,
  pool: null,
  isRulesModalOpened: false,
}));

export const useLeaderBoardStore = () => useStore(leaderBoardStore);
