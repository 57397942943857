import { projectDetailsStore } from "store/useProjectDetailsStore";
import { $api } from "./interceptor";
import { projectsStore } from "store/useProjectsStore";
import { toast } from "App";

const projectsApi = () => {
  const { setState } = projectsStore;

  const getProjects = async () => {
    try {
      const response = await $api.get("/projects");
      setState({ isLoading: true });
      if (response.status === 200) {
        setState({
          projects: response.data,
        });
      }
    } catch (error) {
      setState({
        projects: [],
      });
    }
  };

  const getProject = async (projectID) => {
    const response = await $api.get(`/projects/${projectID}`);
    projectDetailsStore.setState({ project: response.data });
  };

  const postProject = async (payload) => {
    try {
      const response = await $api.post(`/projects`, payload);
      toast({
        title: "Project successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      return response.data;
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const patchProject = async (projectID, payload) => {
    return await $api.patch(`/projects/${projectID}`, payload);
  };

  const handleProjectInfoSubmit = () => {};

  const postProjectRound = async (values) => {
    const {
      start,
      end,
      fixedAllocation,
      minAllocation,
      maxAllocation,
      allocation,
      ...rest
    } = values;
    const { project } = projectDetailsStore.getState();

    const filteredRest = Object.fromEntries(
      Object.entries(rest).filter(([_, value]) => typeof value !== "function")
    );

    const round = {
      ...filteredRest,
      start: new Date(start).toISOString(),
      end: new Date(end).toISOString(),
    };

    if (fixedAllocation) {
      round.allocation = allocation;
    } else {
      round.minAllocation = minAllocation;
      round.maxAllocation = maxAllocation;
    }

    try {
      await $api.post(`/projects/${project.id}/rounds`, round);
      await getProject(project.id);
      toast({
        title: "Project rounds successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const patchProjectRound = async (projectID, roundID, round) => {
    const {
      start,
      end,
      fixedAllocation,
      allocation,
      minAllocation,
      maxAllocation,
      ...rest
    } = round;

    const payload = {
      ...rest,
    };

    if (start) {
      payload.start = new Date(start).toISOString();
    }
    if (end) {
      payload.end = new Date(end).toISOString();
    }

    if (fixedAllocation) {
      payload.allocation = allocation;
    } else {
      payload.minAllocation = minAllocation;
      payload.maxAllocation = maxAllocation;
    }

    try {
      await $api.patch(`/projects/${projectID}/rounds/${roundID}`, payload);
      await getProject(projectID);
      toast({
        title: "Project rounds successfully updated.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const patchProjectDetails = async (projectID, payload) => {
    try {
      const { project } = projectDetailsStore.getState();
      await patchProject(projectID, { ...project, details: payload });
      await getProject(projectID);
      toast({
        title: "Project details successfully updated.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const patchProjectInfo = async (projectID, payload) => {
    try {
      await patchProject(projectID, payload);

      toast({
        title: "Project info successfully updated.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const clear = () => {
    projectDetailsStore.setState({ project: null });
  };

  return {
    getProjects,
    getProject,
    postProject,
    patchProject,
    patchProjectInfo,

    postProjectRound,
    patchProjectRound,
    patchProjectDetails,
    handleProjectInfoSubmit,

    clear,
  };
};

export const projectsService = projectsApi();
