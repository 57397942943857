import { debounce } from "utilities/debounce";

export const tableApi = (tableStore = {}) => {
  const { setState, getState } = tableStore;

  const setPagesCount = (pagesCount) => {
    setState({ pagesCount });
  };

  const gotoPage = (page) => {
    setState({ page });
  };
  const gotoPreviousPage = () => {
    const { page } = getState();

    setState({ page: page - 1 });
  };
  const gotoNextPage = () => {
    const { page } = getState();

    setState({ page: page + 1 });
  };

  const setPageSize = (pageSize) => {
    setState({ pageSize, page: 0 });
  };

  const setSearch = (searchValue) => {
    setState({ searchValue, page: 0 });
  };

  const debouncedSetSearch = debounce(setSearch, 500);

  const handleSearchInputValue = (searchInputValue) => {
    setState({ searchInputValue });
    debouncedSetSearch(searchInputValue);
  };

  const isPreviousPageAvailable = () => getState().page > 0;
  const isNextPageAvailable = () => getState().page + 1 < getState().pagesCount;

  return {
    gotoNextPage,
    gotoPage,
    gotoPreviousPage,
    setPageSize,
    setSearch,
    setPagesCount,
    isNextPageAvailable,
    isPreviousPageAvailable,
    handleSearchInputValue,
  };
};
