import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import { useNavigate } from "react-router-dom";
import illustration from "assets/img/auth/auth.png";
import { signInService } from "services/SignIn";
import { useSignInStore } from "store/useSignInStore";
import { useSignUpStore } from "store/useSignUpStore";

export const SignIn = () => {
  const { setLoginSignIn, setPasswordSignIn, loginSignIn, passwordSignIn, messageFromRequestSignIn } =
    useSignInStore();
  const { isActiveButton, setIsActiveButton } = useSignUpStore();
  const navigate = useNavigate();
  const handleSignInClick = async () => {
    await signInService.sendLoginRequest(navigate);
  };

  useEffect(() => {
    setIsActiveButton(!!loginSignIn && !!passwordSignIn);
  }, [loginSignIn, passwordSignIn, setIsActiveButton]);

  const handleLoginChange = (event) => {
    const newLogin = event.target.value;
    setLoginSignIn(newLogin);
    setIsActiveButton(!!newLogin && !!passwordSignIn);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPasswordSignIn(newPassword);
    setIsActiveButton(!!loginSignIn && !!newPassword);
  };

  const textColor = useColorModeValue("navy.700", "#000");

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="48px" mb="40px">
            Sign In
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              textTransform="uppercase"
            >
              Login
            </FormLabel>
            <Input
              onChange={handleLoginChange}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              color="light.400"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Enter your login"
              mb="16px"
              fontWeight="500"
              autoComplete="username"
              size="lg"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              textTransform="uppercase"
            >
              Password
            </FormLabel>
            <Input
              onChange={handlePasswordChange}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              color="light.400"
              ms={{ base: "0px", md: "0px" }}
              type="password"
              placeholder="Enter your password"
              mb="16px"
              fontWeight="500"
              autoComplete="current-password"
              size="lg"
            />
            {messageFromRequestSignIn && (
              <Text
                color="rgba(239,68,68,1)"
                fontSize="12px"
                fontWeight="500"
                textTransform="uppercase"
                mb={4}
              >
                {messageFromRequestSignIn}
              </Text>
            )}
            <Flex flexDirection="column">
              <Button
                onClick={handleSignInClick}
                isDisabled={!isActiveButton}
                bg="light.400"
                fontSize="sm"
                color="dark.400"
                variant="brand"
                textTransform="uppercase"
                fontWeight="600"
                borderRadius="42px"
                w="100%"
                h="50"
                mb="24px"
                _hover={{ bg: "light.400", opacity: 0.9 }}
                _disabled={{
                  _hover: {
                    bg: "light.400",
                    opacity: 0.5,
                    cursor: "not-allowed",
                  },
                  bg: "light.400",
                  opacity: 0.5,
                  cursor: "not-allowed",
                }}
              >
                Sign In
              </Button>
            </Flex>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          ></Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};
