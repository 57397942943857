import { toast } from "App";
import { $api } from "./interceptor";
import { requestsStore } from "store/useRequestsStore";

const requestsApi = () => {
  const { setState } = requestsStore;

  const getRequests = async () => {
    try {
      setState({ isLoading: true });
      const response = await $api.get("/api/snapshot-tasks/results");
      if (response.status === 200) {
        setState({
          requests: response.data.data?.filter(
            ({ status }) => status === "REVIEW"
          ),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setState({ isLoading: false });
    }
  };

  const getPaginatedRequests = async ({
    page = 0,
    pageSize = 10,
    setPagesCount,
    searchValue,
  }) => {
    try {
      setState({ isLoading: true });
      const response = await $api.get("/api/snapshot-tasks/results", {
        params: {
          page: page + 1,
          limit: pageSize,
          search: searchValue,
          "filter.status": "$eq:REVIEW",
        },
      });

      if (response.status === 200) {
        setState({
          requests: response.data.data,
        });
        setPagesCount(response.data.meta.totalPages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setState({ isLoading: false });
    }
  };

  const getRequest = async (id) => {
    try {
      const response = await $api.get("/api/snapshot-tasks/results", {
        params: { search: id },
      });
      setState({ selectedRequest: response.data.data?.at(0) });
    } catch (error) {
      console.log(error);
    }
  };

  const approveRequest = async (id) => {
    try {
      await $api.post(`api/snapshot-tasks/approve/${id}`);

      toast({
        title: "Task approved.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const rejectRequest = async (id) => {
    try {
      await $api.post(`api/snapshot-tasks/reject/${id}`);

      toast({
        title: "Task rejected.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const clearSelectedRequest = () => {
    setState({ selectedRequest: null });
  };

  return {
    getRequests,
    getRequest,
    clearSelectedRequest,
    approveRequest,
    rejectRequest,
    getPaginatedRequests,
  };
};

export const requestsService = requestsApi();
