import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { $api } from "services/interceptor";

export const ReferralsCount = () => {
  const [referralLink, setReferralLink] = useState("");
  const [telegramUserId, setTelegramUserId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const textColor = useColorModeValue("navy.700", "#000");
  const handleReferralLinkChange = (event) => {
    setReferralLink(event.target.value);
    setResponseMessage("");
  };

  const extractTelegramUserId = () => {
    const regex = /startapp=(\d+)/;
    const match = referralLink.match(regex);
    return match ? match[1] : null;
  };

  const sendTelegramUserId = async (userId) => {
    setIsButtonDisabled(true);
    try {
      const response = await $api.get(`api/referrals/${userId}/referrals-count`);
      setResponseMessage(`User with ID ${userId} has referred ${response.data.referralCount} users successfully`);
    } catch (error) {
    console.log(error?.response?.data?.message);
      setResponseMessage(error?.response?.data?.message || "Failed to fetch referral count");
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleSubmit = () => {
    const userId = extractTelegramUserId();
    if (userId) {
      setTelegramUserId(userId);
      sendTelegramUserId(userId);
    } else {
      setResponseMessage("Invalid referral link");
    }
  };

  return (
    <Box margin={`100px 20px`}>
      <FormControl>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
          textTransform="uppercase"
        >
          Referral Link
        </FormLabel>
        <Input
          value={referralLink}
          onChange={handleReferralLinkChange}
          variant="auth"
          fontSize="sm"
          color="light.400"
          placeholder="Paste the referral link here"
          mb="16px"
          fontWeight="500"
          size="lg"
        />
        <Button
          onClick={handleSubmit}
          isDisabled={isButtonDisabled}
          bg="light.400"
          fontSize="sm"
          color="dark.400"
          variant="brand"
          textTransform="uppercase"
          fontWeight="600"
          w="100%"
          h="50"
          mb="24px"
          _hover={{ bg: "light.400", opacity: 0.9 }}
          _disabled={{
            _hover: {
              bg: "light.400",
              opacity: 0.5,
              cursor: "not-allowed",
            },
            bg: "light.400",
            opacity: 0.5,
            cursor: "not-allowed",
          }}
        >
          Get Referrals
        </Button>
      </FormControl>
      {responseMessage && (
        <Text mt="16px" fontSize="lg" padding="2" borderRadius="10" color="light.800" background="telegram.500" width="60%">
          {responseMessage}
        </Text>
      )}
    </Box>
  );
};
