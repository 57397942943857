import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";

export const FormInput = ({
  value,
  handleChange,
  name,
  type = "text",
  onChange,
  withoutConversion = false,
  ...inputProps
}) => {
  const onInputChange = (e) => {
    if (type === "number") {
      if (withoutConversion) {
        handleChange(e);
      } else {
        handleChange(Number(e));
      }
      return;
    }
    handleChange(e.target.value);
  };

  return (
    <FormControl id={name} mb={4}>
      <FormLabel>
        <Flex gap={6}>
          <Box w={120} wordBreak={"break-word"}>
            {name}
          </Box>
          {type === "number" ? (
            <NumberInput
              maxW={200}
              type={type}
              color="white"
              value={value || 0}
              onChange={onInputChange}
              {...inputProps}
            >
              <NumberInputField textColor={"white"} />
              <NumberInputStepper height={10}>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          ) : (
            <Input
              maxW={200}
              type={type}
              color="white"
              value={value}
              onChange={onInputChange}
              {...inputProps}
            />
          )}
        </Flex>
      </FormLabel>
    </FormControl>
  );
};
