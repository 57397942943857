// Chakra Imports
import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
// Custom Components
import PropTypes from "prop-types";
import React from "react";
import userIcon from "../../assets/img/profile/userIcon.svg";
import { signInService } from "services/SignIn";
import { useNavigate } from "react-router-dom";
export default function HeaderLinks(props) {
  const navigate = useNavigate();
  return (
    <div>
      <Popover placement="bottom-start">
        <PopoverTrigger>
          <Button
            w={12}
            h={12}
            borderRadius="50%"
            border="1px solid rgba(255,255,255,.3)"
            bg="transparent"
            _hover={{
              bg: "transparent",
              transform: "scale(.95)",
              borderColor: "rgba(255,255,255,.6)",
            }}
            p={0}
          >
            <img src={userIcon} alt="" />
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent bg={"black"} width={100}>
            <PopoverBody>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    signInService.logout(navigate);
                  }}
                >
                  Logout
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
