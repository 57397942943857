import {
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export const RejectModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  userName,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent bg={"black"}>
        <ModalHeader>
          Are you sure you want to reject request for {userName}?
        </ModalHeader>
        <ModalCloseButton />
        <Flex alignItems={"center"} justifyContent={"center"}>
          <Button color="dark.400" maxW="200px" w="100%" onClick={handleSubmit}>
            Yes
          </Button>
          <Button color="dark.400" maxW="200px" w="100%" onClick={handleClose}>
            No
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
