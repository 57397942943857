import React, { useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ApproveModal } from "./components/ApproveModal";
import { RejectModal } from "./components/RejectModal";
import { TableWithHeader } from "../components/TableWithHeader";
import { useRequestsStore } from "store/useRequestsStore";
import { requestsService } from "services/requestsService";
import { useServerSidePagination } from "hooks/useServerSidePagination";

export const RequestsView = () => {
  const { requests, isLoading } = useRequestsStore();
  const { refetch, tableStore, tableService } = useServerSidePagination(
    requestsService.getPaginatedRequests
  );

  const [approveTaskInfo, setApproveTaskInfo] = useState(null);
  const [rejectTaskInfo, setRejectTaskInfo] = useState(null);

  const handleRejectClick = (name, id) => {
    setRejectTaskInfo({ name, id });
  };
  const handleApproveClick = (name, id) => {
    setApproveTaskInfo({ name, id });
  };

  const handleModalClose = () => {
    setApproveTaskInfo(null);
    setRejectTaskInfo(null);
  };

  const columns = [
    {
      name: "Task",
      accessor: "id",
      Cell: ({
        row: {
          original: { id, userTask },
        },
      }) => {
        return <Link to={`${id}`}>{userTask?.title}</Link>;
      },
    },
    {
      name: "User name",
      accessor: "user",
      Cell: ({
        row: {
          original: { userTask },
        },
      }) => {
        return (
          <div>
            {userTask?.user?.profile?.surname || userTask?.user?.profile?.name
              ? `${userTask?.user?.profile?.name} ${userTask?.user?.profile?.surname}`
              : userTask?.user?.email}
          </div>
        );
      },
    },
    {
      name: "Actions",
      id: "actions",
      Cell: ({
        row: {
          original: { userTask, status },
        },
      }) => {
        return (
          status === "REVIEW" && (
            <>
              <Button
                bg={"green"}
                textColor={"white"}
                onClick={() => {
                  handleApproveClick(
                    userTask?.user?.profile?.surname ||
                      userTask?.user?.profile?.name
                      ? `${userTask?.user?.profile?.name} ${userTask?.user?.profile?.surname}`
                      : userTask?.user?.email,
                    userTask.userTaskId
                  );
                }}
              >
                Approve
              </Button>
              <Button
                bg={"red"}
                textColor={"white"}
                onClick={() => {
                  handleRejectClick(
                    userTask?.user?.profile?.surname ||
                      userTask?.user?.profile?.name
                      ? `${userTask?.user?.profile?.name} ${userTask?.user?.profile?.surname}`
                      : userTask?.user?.email,
                    userTask.userTaskId
                  );
                }}
              >
                Reject
              </Button>
            </>
          )
        );
      },
    },
  ];

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <TableWithHeader
        columns={columns}
        tableData={requests}
        tableName={"Requests"}
        isDataLoading={isLoading}
        tableStore={tableStore}
        tableService={tableService}
      />

      <ApproveModal
        isOpen={!!approveTaskInfo}
        userName={approveTaskInfo?.name}
        handleClose={handleModalClose}
        handleSubmit={async () => {
          if (approveTaskInfo?.id) {
            await requestsService.approveRequest(approveTaskInfo.id);
            await refetch();
            handleModalClose();
          }
        }}
      />

      <RejectModal
        isOpen={!!rejectTaskInfo}
        userName={rejectTaskInfo?.name}
        handleClose={handleModalClose}
        handleSubmit={async () => {
          if (rejectTaskInfo?.id) {
            await requestsService.rejectRequest(rejectTaskInfo.id);
            await refetch();

            handleModalClose();
          }
        }}
      />
    </Box>
  );
};
