import { create, useStore } from "zustand";

export const tasksStore = create((set) => ({
  tasks: [],
  loading: false,
  isSetOrderPressed: false,
  orderTasks: [],
  setOrderTask: (id, order) =>
    set((state) => {
      const index = state.orderTasks.findIndex((task) => task.taskId === id);
      const newOrder = order === 0 ? null : order;
  
      if (index !== -1) {
        const updatedTasks = state.orderTasks.map((task) =>
          task.taskId === id ? { ...task, order: newOrder } : task
        );
        return { orderTasks: updatedTasks };
      } else {
        return { orderTasks: [...state.orderTasks, { taskId: id, order: newOrder }] };
      }
    })
}));

export const useTasksStore = () => useStore(tasksStore);
