import React, { useEffect } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { requestsService } from "services/requestsService";
import { useRequestsStore } from "store/useRequestsStore";

export const RequestDetailsView = () => {
  const { selectedRequest } = useRequestsStore();

  const { requestID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    requestsService.getRequest(requestID);
  }, [requestID]);

  if (!requestID) {
    return null;
  }

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <Flex gap={4} flexDirection={"column"}>
        {selectedRequest && (
          <>
            <Box>STATUS: {selectedRequest.status}</Box>
            <Box>Task name: {selectedRequest.userTask.title}</Box>
            <Box wordBreak={"break-all"}>
              Description: {selectedRequest.userTask.description}
            </Box>
            <Box>
              {selectedRequest?.snapshotsUrls?.map((imgSrc, i) => (
                <img key={i} width={400} src={imgSrc} alt="requestImage" />
              ))}
            </Box>
          </>
        )}

        <Button
          colorScheme="teal"
          onClick={() => {
            navigate("/requests");
          }}
        >
          Go back to requests
        </Button>
      </Flex>
    </Box>
  );
};
