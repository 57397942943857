import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import { useNavigate } from "react-router-dom";
import illustration from "assets/img/auth/auth.png";
import { signInService } from "services/SignIn";
import { useState, useEffect, useCallback } from "react";
import { useSignInStore } from "store/useSignInStore";
import { useSignUpStore } from "store/useSignUpStore";
import { isCodeValid } from "helpers/isCodeValid";

export const CodeView = () => {
  const navigate = useNavigate();
  const { emailSignIn } = useSignInStore();
  const { code, setCode, setIsActiveButton } = useSignUpStore();
  const [showErrorVerifCode, setShowErrorVerifCode] = useState(false);
  const { messageFromRequestSignIn } = useSignInStore();
  const handleCodeChange = (otpValue) => {
    setShowErrorVerifCode(false);
    setCode(otpValue);
  };
  const handleNextClickLogin = useCallback(async () => {
    try {
      await signInService.sendOtpLoginRequest(navigate);
    } catch (error) {
      setShowErrorVerifCode(!showErrorVerifCode);
    }
  }, [showErrorVerifCode, navigate]);

  useEffect(() => {
    setIsActiveButton(isCodeValid(code));
  }, [code, setIsActiveButton]);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        <Box me="auto" mb={10}>
          <Heading fontSize="48px" mb="16px">
            Enter Verification <br></br>
            Code
          </Heading>
          <Text opacity={0.6} letterSpacing={1}>
            The code was sent to {emailSignIn || "Your email"}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              mb="8px"
              textTransform="uppercase"
            >
              Enter Verification Code
            </FormLabel>
            <Input
              onChange={(e) => handleCodeChange(e.target.value)}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              color="light.400"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              mb="16px"
              fontWeight="500"
              size="lg"
            />
            {messageFromRequestSignIn && (
              <Text
                mb="16px"
                color="rgba(239, 68, 68,1)"
                fontSize={12}
                fontWeight={500}
                textTransform="uppercase"
              >
                {messageFromRequestSignIn}
              </Text>
            )}
            <Flex flexDirection="column">
              <Button
                onClick={handleNextClickLogin}
                fontSize="sm"
                me="0px"
                py="15px"
                h="50px"
                borderRadius="42px"
                bg="light.400"
                textTransform="uppercase"
                color="dark.400"
                fontWeight="600"
              >
                Enter
              </Button>
            </Flex>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          ></Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};
