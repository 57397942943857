export const globalStyles = {
  colors: {
    dark: {
      400: "#000000",
      500: "#070809",
      600: "#0E1012",
    },
    light: {
      400: "#FFFFFF",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    accent: {
      400: "#E5FD55",
      500: "#76ED00",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: "dark.500",
        fontFamily: "DM Sans",
        letterSpacing: "-0.5px",
        color: "light.400",
      },
      input: {
        color: "gray.700",
      },
      html: {
        bg: "dark.500",
        fontFamily: "DM Sans",
      },
    }),
  },
};
