import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useUsersStore } from "store/useUsersStore";
import { usersService } from "services/usersService";

// const usersStatistics = [
//   { label: "Total users", key: "totalUsersCount" },
//   { label: "Kol user", key: "kolUsersCount" },
//   { label: "Basic user", key: "basicUsersCount" },
//   { label: "Admin user", key: "adminUsersCount" },
//   { label: "Mini App Users", key: "miniAppUsersCount" },
// ];

export const DashboardView = () => {
  const [botUsersCount, setBotUsersCount] = useState(0);
  const { statistic } = useUsersStore();

  useEffect(() => {
    usersService.getUsersStatistic();
    const fetchUsersCount = async () => {
      const value = await usersService.getUsersCount();
      if (value) {
        setBotUsersCount(value);
      }
    };
    fetchUsersCount();
  }, []);

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <Flex gap={5}>
        <Flex
          flexDirection={"column"}
          gap={2}
          bg={"blue.400"}
          textColor={"black"}
          maxW={"fit-content"}
          padding={4}
        >
          {/* {usersStatistics.map(({ key, label }, i) => ( */}
          <Flex gap={3}>
            <Box>MiniApp Number of Users :</Box>
            <Box>{botUsersCount}</Box>
          </Flex>
          {/* ))} */}
        </Flex>
        {/* <Flex
          flexDirection={"column"}
          gap={2}
          bg={"blue.400"}
          textColor={"black"}
          maxW={"fit-content"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={4}
        >
          <Box>{statistic.passedKycUsersPercent}% users have passed KYC</Box>
        </Flex> */}
      </Flex>
    </Box>
  );
};
