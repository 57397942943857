import { create, useStore } from "zustand";

export const networksStoreInitialState = {
  networks: [],
  isLoading: false,
};

export const networksStore = create(() => ({
  ...networksStoreInitialState,
}));

export const useNetworksStore = () => useStore(networksStore);
