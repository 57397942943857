import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { tasksService } from "services/tasksService";
import { useNavigate, useParams } from "react-router-dom";
import { taskDetailsStore } from "../../../store/useTaskDetailsStore";
import { FormInput } from "./components/FormInput";
import { FormSwitch } from "./components/FormSwitch";
import { taskType } from "store/useCreateTaskFormStore";

export const TaskDetailsView = () => {
  const {
    title,
    order,
    isActive,
    description,
    rewardPointsAmount,
    redirectUrl,
    resourceId,
    type,
    isFeaturedTask,
    iconUrl,
    relativeRewardPercent,
    category,
    setTitle,
    setOrder,
    setIsActive,
    setDescription,
    setRewardPointsAmount,
    setRedirectUrl,
    setResourceId,
    setIsFeaturedTask,
    setRelativeRewardPercent,
    setCategory,
  } = taskDetailsStore();

  const { taskID } = useParams();
  const navigate = useNavigate();

  const [, setPickedImage] = useState(null);
  const [, setSnapshotFile] = useState(null);

  const handleImageChange = async (event) => {
    const file = event.target.files && event.target.files[0];
    setPickedImage(null);

    if (!file?.type?.startsWith("image")) {
      return;
    }

    if (!file) {
      setPickedImage(null);
      return;
    }

    setSnapshotFile(file);

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setPickedImage(String(fileReader.result));
      tasksService.uploadImage(file, false);
    };

    fileReader.readAsDataURL(file);
  };

  useEffect(() => {
    const fetchData = async () => {
      const task = await tasksService.getTask(taskID);
      // if (task) {
      //   setCategory(task.category);
      // }
    };

    fetchData();
  }, [taskID]);

  if (!taskID) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedTask = {
      title,
      description,
      rewardPointsAmount,
      redirectUrl,
      order,
      resourceId,
      type,
      isFeaturedTask,
      relativeRewardPercent,
      category,
    };
    tasksService.patchTask(taskID, updatedTask);
  };

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <form onSubmit={handleSubmit}>
        <Box>
          <FormInput
            name="title"
            handleChange={setTitle}
            value={title}
            required
          />
          <FormInput
            name="description"
            handleChange={setDescription}
            value={description}
            required
          />

          <FormControl id="category" mb={4} isRequired>
            <FormLabel>
              <Flex gap={6}>
                <Box w={120} wordBreak={"break-word"}>
                  Category
                </Box>
                <Select
                  maxW={200}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="" disabled>Task</option>
                  <option value="QUEST">Quest</option>
                  <option value="PARTNER_TASK">Partner Task</option>
                  <option value="ON_CHAIN">On Chain</option>
                  <option value="FREN">Fren</option>
                </Select>
              </Flex>
            </FormLabel>
          </FormControl>

          <FormInput
            name="rewardPointsAmount"
            handleChange={setRewardPointsAmount}
            value={rewardPointsAmount}
            type="number"
          />

          <FormInput
            name="RedirectURL"
            handleChange={setRedirectUrl}
            value={redirectUrl}
          />

          <FormInput
            name="Order"
            handleChange={setOrder}
            value={order}
            type="number"
            min={0}
          />

          {type === taskType.telegram && (
            <FormInput
              name="Resource ID"
              handleChange={setResourceId}
              value={resourceId}
            />
          )}

          <FormInput
            name="Relative Reward Percent"
            handleChange={setRelativeRewardPercent}
            value={relativeRewardPercent}
            type="number"
          />

          <FormSwitch
            label={"Active"}
            value={isActive}
            handleChange={setIsActive}
          />

          <FormSwitch
            label={"Is Task Featured"}
            value={isFeaturedTask}
            handleChange={setIsFeaturedTask}
          />
        </Box>

        <Box
          sx={{
            bgColor: "white",
            color: "black",
            cursor: "pointer",
            width: "200px",
            borderRadius: "10px",
            marginBottom: "10px",
          }}
        >
          <label
            htmlFor="choose-icon"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "200px",
              height: "50px",
            }}
          >
            <input
              accept="image/*"
              type="file"
              id="choose-icon"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <p>Choose image</p>
          </label>
        </Box>

        {iconUrl && (
          <Box>
            <img src={iconUrl} alt="" />
            <button
              style={{
                backgroundColor: "red",
                color: "white",
                cursor: "pointer",
                padding: "10px",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
              onClick={() => taskDetailsStore.setState({ iconUrl: "" })}
            >
              Delete
            </button>
          </Box>
        )}

        <Button colorScheme="teal" type="submit">
          Save
        </Button>

        <Button
          colorScheme="teal"
          onClick={() => {
            navigate("/tasks");
          }}
        >
          Go back to tasks
        </Button>
      </form>
    </Box>
  );
};
