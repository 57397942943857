import { create, useStore } from "zustand";

export const quizTaskStoreInitialState = {
  projects: [],
  isLoading: false,
};

export const projectsStore = create(() => ({
  ...quizTaskStoreInitialState,
}));

export const useProjectsStore = () => useStore(projectsStore);
