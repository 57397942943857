import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = ({
  children,
  redirectCondition = !localStorage.getItem("accessToken"),
  redirectRoute = "/auth",
}) => {
  if (redirectCondition) {
    return <Navigate to={redirectRoute} replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};
