import { create, useStore } from 'zustand';

const initialState = {
  loginSignIn: '',
  passwordSignIn: '',
  isLoading: false,
  isLoadingAuth: false,
  setLoginSignIn: (loginSignIn) => {},
  setPasswordSignIn: () => {},
};

export const signInStore = create((set) => ({
  ...initialState,
  setLoginSignIn: (newLogin) => set({ loginSignIn: newLogin }),
  setPasswordSignIn: (newPassword) => {
    set({ passwordSignIn: newPassword });
  },
}));

export const useSignInStore = () => useStore(signInStore);
