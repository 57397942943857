import { Box, Flex, Stack } from "@chakra-ui/react";
import logo from '../../../assets/img/logo.jpg';
import Links from "components/sidebar/components/Links";
import React from "react";


// FUNCTIONS

export const SidebarContent = (props) => {
  const { routes } = props;
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Flex p="1rem" align={'center'} gap={4}>
        <img width={40} src={logo} alt="logo" />
          <b>HYBRID</b>
      </Flex>
      <Stack direction='column' mb='auto' mt='8px'>
        <Box pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>
    </Flex>
  );
}
