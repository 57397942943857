import { networksStore } from "store/useNetworksStore";
import { $api } from "./interceptor";
import { toast } from "App";

const networksApi = () => {
  const { setState } = networksStore;

  const getNetworks = async () => {
    try {
      const response = await $api.get("/networks");
      setState({ isLoading: true });
      if (response.status === 200) {
        setState({
          networks: response.data,
        });
      }
    } catch (error) {
      setState({
        networks: [],
      });
    } finally {
      setState({ isLoading: false });
    }
  };

  const postNetwork = async (payload) => {
    try {
      await $api.post("/networks", payload);
      toast({
        title: "Network successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const patchNetwork = async (networkID, payload) => {
    try {
      await $api.patch(`/networks/${networkID}`, payload);
      toast({
        title: "Network successfully updated.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const deleteNetwork = async (networkID) => {
    try {
      await $api.delete(`/networks/${networkID}`);
      toast({
        title: "Network successfully deleted.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      getNetworks();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return { getNetworks, postNetwork, patchNetwork, deleteNetwork };
};

export const networksService = networksApi();
