import React, { useEffect, useState } from "react";
import { Box, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { TableWithHeader } from "../components/TableWithHeader";
import { useLeaderBoardStore } from "store/userLeaderBoardStore";
import { leaderBoardService } from "services/leaderboardService";
import { SearchIcon } from "@chakra-ui/icons";

export const LeaderboardView = () => {
  const { leadersList, isLoading } = useLeaderBoardStore();
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    leaderBoardService.getLeadersList();
  }, []);

  const filteredList = leadersList.filter((leader) =>
    leader.username?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    { name: "ID", accessor: "telegramUserId" },
    {
      name: "Name",
      accessor: "username",
      Cell: ({
        row: {
          original: { username, telegramUserId },
        },
      }) => {
        return (
          <Link to={`/users/${telegramUserId}`}>{username || "NO NAME"}</Link>
        );
      },
    },
    { name: "Position", accessor: "position" },
  ];

  const handleRowClick = (row) => {
    navigate(`/user/${row.telegramUserId}`);
  };

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <InputGroup my={4} maxW="500px" mx="auto">
        <Input
          placeholder="Search by username"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} 
        />
        <InputRightElement children={<SearchIcon color="gray.300" />} />
      </InputGroup>

      <TableWithHeader
        columns={columns}
        tableData={filteredList}
        tableName={"Top 300 Users"}
        isDataLoading={isLoading}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};