import React from "react";
import userIcon from "assets/img/profile/userIcon.svg";
import DataTables from "views/admin/dataTables";

const routes = [
  {
    name: "Dashboard",
    layout: "",
    path: "/",
    icon: <img src={userIcon} alt="userIcon" />,
    element: DataTables,
  },
  {
    name: "Users",
    layout: "",
    path: "/users",
    icon: <img src={userIcon} alt="userIcon" />,
    element: DataTables,
  },
  {
    name: "Leaderboard",
    layout: "",
    path: "/leaderbaord",
    icon: <img src={userIcon} alt="userIcon" />,
    element: DataTables,
  },
  {
    name: "Referral Count",
    layout: "",
    path: "/referral-count",
    icon: <img src={userIcon} alt="userIcon" />,
    element: DataTables,
  },
  {
    name: "Tasks",
    layout: "",
    path: "/tasks",
    icon: <img src={userIcon} alt="userIcon" />,
    element: DataTables,
  },
  // {
  //   name: "Tasks statistic",
  //   layout: "",
  //   path: "/tasksStatistic",
  //   icon: <img src={userIcon} alt="userIcon" />,
  //   element: DataTables,
  // },
  {
    name: "Requests",
    layout: "",
    path: "/requests",
    icon: <img src={userIcon} alt="userIcon" />,
    element: DataTables,
  },
  // {
  //   name: "Projects",
  //   layout: "",
  //   path: "/projects",
  //   icon: <img src={userIcon} alt="userIcon" />,
  //   element: DataTables,
  // },
  // {
  //   name: "Networks",
  //   layout: "",
  //   path: "/networks",
  //   icon: <img src={userIcon} alt="userIcon" />,
  //   element: DataTables,
  // },
  // {
  //   name: "Tokens",
  //   layout: "",
  //   path: "/tokens",
  //   icon: <img src={userIcon} alt="userIcon" />,
  //   element: DataTables,
  // },
];

export default routes;
