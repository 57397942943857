import { signInStore } from "store/useSignInStore";
import { handleSignInError } from "../utilities/axiosErrors";
import { $api, API_URL } from "./interceptor";
import axios from "axios";

const SignIn = () => {
  const { setState, getState } = signInStore;

  const sendLoginRequest = async (navigate) => {
    const { loginSignIn, passwordSignIn } = getState();
    try {
      setState({ isLoading: true });
      const requestData = {
        login: loginSignIn,
        password: passwordSignIn,
      };
      const response = await $api.post("/api/auth/admin", requestData);

      const accessToken = response?.data?.tokens?.access;

      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        setState({
          messageFromRequestSignIn: "",
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      handleSignInError(error, setState);
    } finally {
      setState({ isLoading: false });
    }
  };

  const logout = async (navigate) => {
    await $api.post("/api/auth/logout");
    localStorage.removeItem("accessToken");
    navigate("/auth");
  };

  const sendRefreshRequest = async () => {
    try {
      const storageAccessToken = localStorage.getItem("accessToken");
      const resp = await axios.post(
        `${API_URL}/api/auth/logout`,
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `${storageAccessToken}`,
          },
        }
      );
      const accessToken = resp.data.access;
      localStorage.setItem("accessToken", accessToken);
      return true;
    } catch (error) {
      localStorage.removeItem("accessToken");
      window.location.replace("/#/");
      return false;
    }
  };

  return {
    sendLoginRequest,
    logout,
    sendRefreshRequest,
  };
};

export const signInService = SignIn();
