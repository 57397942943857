import { create, useStore } from "zustand";

export const createTableStoreFunction = (set) => ({
  page: 0,
  pageSize: 10,
  searchValue: "",
  searchInputValue: "",
  pagesCount: 0,
  clear: () =>
    set({
      page: 0,
      pageSize: 10,
      searchValue: "",
      searchInputValue: "",
      pagesCount: 0,
    }),
});

export const tableStore = create(createTableStoreFunction);

export const useTableStore = () => useStore(tableStore);
