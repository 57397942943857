import { create, useStore } from "zustand";

const initialState = {
  requests: [],
  selectedRequest: null,
  isLoading: false,
};

export const requestsStore = create(() => ({
  ...initialState,
}));

export const useRequestsStore = () => useStore(requestsStore);
