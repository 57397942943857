import { Box, Flex, Select, Skeleton } from "@chakra-ui/react";
import React, { useMemo } from "react";
import CheckTable from "./CheckTable";
import { TableSearch } from "./TableSearch";
import { useStore } from "zustand";

export const PaginatedTable = ({
  columnsData,
  tableData,
  isDataLoading,
  tableStore,
  tableService,
}) => {
  const { page, pageSize, pagesCount } = useStore(tableStore);

  const mockedRows = useMemo(() => new Array(pageSize).fill(true), [pageSize]);

  return (
    <>
      <Box maxWidth={400} mb={2} mt={2}>
        <TableSearch tableService={tableService} tableStore={tableStore} />
      </Box>
      {isDataLoading ? (
        mockedRows.map((_, i) => (
          <Box key={i} height={53}>
            <Skeleton height={20}></Skeleton>
          </Box>
        ))
      ) : (
        <CheckTable columnsData={columnsData} tableData={tableData} />
      )}
      <Flex gap={10} alignItems={"center"} mt={10}>
        <Flex gap={4}>
          <button
            onClick={() => tableService.gotoPage(0)}
            disabled={!tableService.isPreviousPageAvailable()}
          >
            {"<<"}
          </button>
          <button
            onClick={() => tableService.gotoPreviousPage()}
            disabled={!tableService.isPreviousPageAvailable()}
          >
            {"<"}
          </button>
          <button
            onClick={() => tableService.gotoNextPage()}
            disabled={!tableService.isNextPageAvailable()}
          >
            {">"}
          </button>
          <button
            onClick={() => tableService.gotoPage(pagesCount - 1)}
            disabled={!tableService.isNextPageAvailable()}
          >
            {">>"}
          </button>
          <span>
            Page{" "}
            <strong>
              {page + 1} of {pagesCount || 1}
            </strong>
          </span>
        </Flex>
        <Flex gap={4} alignItems={"center"} flexGrow={1}>
          <Select
            value={pageSize}
            maxWidth={100}
            onChange={(e) => {
              tableService.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
          <Box>items per page</Box>
        </Flex>
      </Flex>
    </>
  );
};
