import { Input } from "@chakra-ui/react";
import { useStore } from "zustand";

export const TableSearch = ({ tableService, tableStore }) => {
  const { searchInputValue } = useStore(tableStore);

  return (
    <Input
      value={searchInputValue}
      color={"white"}
      onChange={(e) => tableService.handleSearchInputValue(e.target.value)}
    />
  );
};
