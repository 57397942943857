import { create, useStore } from "zustand";

const initialState = {
  code: "",
  isActiveButton: false,
};

export const signUpStore = create((set) => ({
  ...initialState,
  setIsActiveButton: (isActiveButton) => set({ isActiveButton }),
  setCode: (code) => {
    set({ code });
  },
}));

export const useSignUpStore = () => useStore(signUpStore);
