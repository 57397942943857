import { Flex, FormControl, FormLabel, Switch } from "@chakra-ui/react";

export const FormSwitch = ({ value, handleChange, label, ...inputProps }) => {
  const onChange = () => {
    handleChange(!value);
  };

  return (
    <FormControl mb={4} width={200}>
      <FormLabel cursor={"pointer"}>
        <Flex gap={2} alignItems={"center"}>
          {label}
          <Switch
            color="white"
            isChecked={value}
            onChange={onChange}
            {...inputProps}
          />
        </Flex>
      </FormLabel>
    </FormControl>
  );
};
