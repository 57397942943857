import React from "react";
import { Box } from "@chakra-ui/react";
import { useUsersStore } from "store/useUsersStore";
import { usersService } from "services/usersService";
import { Link } from "react-router-dom";
import { TableWithHeader } from "../components/TableWithHeader";
import { useServerSidePagination } from "hooks/useServerSidePagination";

export const UsersView = () => {
  const { users, isUsersLoading } = useUsersStore();
  const { tableStore, tableService } = useServerSidePagination(
    usersService.getPaginatedUsersList
  );

  const columns = [
    { name: "ID", accessor: "id" },
    {
      name: "Name",
      accessor: "profile.name",
      Cell: ({
        row: {
          original: { id, profile },
        },
      }) => {
        return <Link to={`${id}`}>{profile?.name || "NO NAME"}</Link>;
      },
    },
    { name: "LVL", accessor: "levelData.currentLevel.value" },
    { name: "EMAIL", accessor: "email" },
    {
      name: "KYC",
      accessor: "kyc.status",

      Cell: ({ value }) => {
        return <>{value === "APPROVED" ? "Verified" : "Unverified"}</>;
      },
    },
    { name: "Wallet", accessor: "connectedWallets[0].address" },
  ];

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <TableWithHeader
        columns={columns}
        tableData={users}
        tableName={"Users"}
        isDataLoading={isUsersLoading}
        tableStore={tableStore}
        tableService={tableService}
      />
    </Box>
  );
};
