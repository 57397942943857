import { useCallback, useEffect, useState } from "react";
import { create, useStore } from "zustand";
import { createTableStoreFunction } from "store/useTableStore";
import { tableApi } from "services/tableService";

const createTableInstance = () => {
  const store = create(createTableStoreFunction);
  const service = tableApi(store);

  return {
    tableStore: store,
    tableService: service,
  };
};

export const useServerSidePagination = (request) => {
  const [{ tableService, tableStore }] = useState(createTableInstance());

  const { page, pageSize, clear, searchValue } = useStore(tableStore);

  useEffect(() => clear, [clear]);

  useEffect(() => {
    request({
      page,
      pageSize,
      setPagesCount: (pagesCount) => {
        tableService.setPagesCount(pagesCount);
      },
      searchValue,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, request, searchValue]);

  const refetch = useCallback(
    () =>
      request({
        page,
        pageSize,
        setPagesCount: (pagesCount) => {
          tableService.setPagesCount(pagesCount);
        },
        searchValue,
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, request, searchValue]
  );

  return { refetch, tableService, tableStore };
};
