import { create, useStore } from "zustand";

export const quizTaskStoreInitialState = {
  questionsAmount: 2,
  approximateTime: 0,
  questions: [],
};

export const quizTaskStore = create((set) => ({
  ...quizTaskStoreInitialState,

  setApproximateTime: (approximateTime) => set({ approximateTime }),
  setQuestionsAmount: (questionsAmount) => set({ questionsAmount }),
}));

export const useQuizTaskStore = () => useStore(quizTaskStore);
