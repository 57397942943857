import { AxiosResponse } from "axios";

import { leaderBoardStore } from "store/userLeaderBoardStore";
import { $api } from "./interceptor";

class LeaderBoardService {
  getLeadersList = async () => {
    try {
      leaderBoardStore.setState({ isLoading: true });
      const response = await $api.get(
        `/api/points/burned-points/admin-leaderboard`
      );
      
      // convert snake_case keys to camelCase before setting leaderboard state
      const leadersList = response.data.participants.map((leader) => {
        const leaderData = {
          position: leader.position,
          telegramUserId: leader.telegram_user_id,
          profileIcon: leader.profile_icon,
          avatarId: leader.avatar_id,
          username: leader.username,
        };
        return leaderData;
      });

      leaderBoardStore.setState({
        leadersList: leadersList,

        isLoading: false,
      });
    } catch (error) {
      leaderBoardStore.setState({
        leadersList: [],
        userResults: null,
        isLoading: false,
      });
    }
  };

  getCurrentPool = async () => {
    try {
      leaderBoardStore.setState({ isLoading: true });
      const response = await $api.get(`/api/points/burned-points/current-pool`);

      leaderBoardStore.setState({
        pool: {
          amount: response.data.amount,
          giveawayDate: response.data.giveawayDate,
        },
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  openRulesModal = () => {
    leaderBoardStore.setState({ isRulesModalOpened: true });
  };

  closeRulesModal = () => {
    leaderBoardStore.setState({ isRulesModalOpened: false });
  };
}

export const leaderBoardService = new LeaderBoardService();
